import { Alert, Button, Card, Col, Form, Pagination, Row, Select, Space, Typography, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { getDataFromLocalStorage, getMonthFromNumber, isEmpty } from '../../generalFunctions';
import RichTextEditor from '../common/RTE/RichTextEditor';
import useApi from '../../hooks/useApi';
import { API_METHODS, IMPLEMENTING_PARTNER, SUMMERY_REPORT_DEFAULT_VALUE } from '../../constants';
import dayjs from 'dayjs';
import printJS from 'print-js';

const MonthlyReports = () => {
    const editorRef = useRef(null);
    const [form] = Form.useForm();
    const { company } = getDataFromLocalStorage("clientConfig");
    const [showRTE, setShowRTE] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedReport, setSelectedReport] = useState(null);
    const [reportData, setReportData] = useState();
    const [defaultReportFormat, setDefaultReportFormat] = useState();
    const [responseData, getRequest] = useApi();
    const [getProjectsByCompanyResponseData, getProjectsByCompany] = useApi();
    const [getProjectReportFormatResponseData, getProjectReportFormat] = useApi();
    const [postResponseData, postRequest] = useApi();
    const [putResponseData, putRequest] = useApi();
    const [reportBody, setReportBody] = useState();
    const [projects, setProjects] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [isReportPresent, setIsReportPresent] = useState(false);
    const [sendReportToCompanyResponse, sendReportToCompanyRequest] = useApi(
        "/admin/summary-report/send",
        null,
        API_METHODS.POST
    );
    const [deleteReportResponse, deleteReportData] = useApi(null);

    const buildCompanyReport = (reportData) => {
        const reportFormat = `<h1 style=\"text-align: center;\"><strong>Monthly Report &ndash; ${reportData.month}</strong>` +
            `</h1>\r\n<table style=\"border-collapse: collapse; width: 99.8353%; border-width: 1px; border-style: solid; margin-left: auto; margin-right: auto; height: 49px;\" border=\"1\">` +
            `<colgroup><col style=\"width: 100%;\"></colgroup>\r\n<tbody>\r\n<tr>\r\n<td style=\"background-color: rgb(206, 212, 217);\">\r\n<h3 style=\"text-align: center;\"><strong>Narrative Report</strong>` +
            `</h3>\r\n</td>\r\n</tr>\r\n</tbody>\r\n</table>` +
            `\r\n<table style=\"border-collapse: collapse; width: 100%; height: 117.563px; border-width: 1px; border-style: solid; margin-left: auto; margin-right: auto;\" border=\"1\">` +
            `<colgroup><col style=\"width: 50.1236%;\"><col style=\"width: 49.8764%;\"></colgroup>\r\n<tbody>\r\n<tr style=\"height: 19.5938px;\">\r\n` +
            `<td style=\"height: 19.5938px;\">Name of the project</td>\r\n<td style=\"height: 19.5938px;\">${reportData.projectName}</td>\r\n</tr>\r\n<tr style=\"height: 19.5938px;\">\r\n` +
            `<td style=\"height: 19.5938px;\">Location</td>\r\n<td style=\"height: 19.5938px;\">${reportData.projectLocation}</td>\r\n</tr>\r\n<tr style=\"height: 19.5938px;\">\r\n` +
            `<td style=\"height: 19.5938px;\">Implementing partner</td>\r\n<td style=\"height: 19.5938px;\">${IMPLEMENTING_PARTNER}</td>\r\n</tr>` +
            `\r\n<tr style=\"height: 19.5938px;\">\r\n<td style=\"height: 19.5938px;\">Report for the month</td>\r\n<td style=\"height: 19.5938px;\">${reportData.month}</td>` +
            `\r\n</tr>\r\n<tr style=\"height: 19.5938px;\">\r\n<td style=\"height: 19.5938px;\">Date of submission</td>\r\n<td style=\"height: 19.5938px;\">${dayjs().format("DD/MM/YYYY")}</td>` +
            `\r\n</tr>\r\n<tr style=\"height: 19.5938px;\">\r\n<td style=\"height: 19.5938px;\">Number of beneficiaries</td>\r\n` +
            `<td style=\"height: 19.5938px;\">${reportData.numberOfBeneficiaries}</td>\r\n</tr>\r\n</tbody>\r\n</table>\r\n<p>&nbsp;</p>\r\n<table style=\"border-collapse: collapse; width: 100%;\" border=\"1\">` +
            `<colgroup><col style=\"width: 99.9165%;\"></colgroup>\r\n<tbody>\r\n<tr>\r\n<td style=\"background-color: rgb(206, 212, 217);\">\r\n<h3 style=\"text-align: center;\">` +
            `<strong>ENVIRONMENT (Technology and systems </strong><strong>executed)</strong></h3>\r\n</td>\r\n</tr>\r\n</tbody>\r\n</table>\r\n<table style=\"border-collapse: collapse; width: 100%;\" border=\"1\">` +
            `<colgroup><col style=\"width: 100%;\"></colgroup>\r\n<tbody>\r\n` +
            `<tr>\r\n<td>\r\n<h4 style=\"text-align: left;\">A) Waste Treatment and compost generation in Hadapsar:&nbsp; ` +
            `&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ` +
            `# data to be collected</h4>\r\n</td>\r\n</tr>\r\n<tr>\r\n<td>\r\n<h4 style=\"text-align: center;\">${reportData.month} - CP Status</h4>\r\n</td>\r\n</tr>\r\n</tbody>\r\n</table>\r\n
        <table style=\"border-collapse: collapse; width: 100%; height: 58.7813px;\" border=\"1\"><colgroup><col style=\"width: 25.1672%;\"><col style=\"width: 11.7048%;\">
        <col style=\"width: 12.5427%;\"><col style=\"width: 12.9599%;\"><col style=\"width: 11.204%;\"><col style=\"width: 13.4615%;\"><col style=\"width: 12.9599%;\"></colgroup>\r\n
        <tbody>\r\n<tr style=\"height: 39.1875px;\">\r\n<td style=\"text-align: center; height: 39.1875px; background-color: rgb(45, 194, 107);\"><strong>Ward</strong></td>\r\n
        <td style=\"height: 39.1875px; text-align: center; background-color: rgb(45, 194, 107);\"><strong>No. of&nbsp;CPs&nbsp;</strong></td>\r\n
        <td style=\"height: 39.1875px; text-align: center; background-color: rgb(45, 194, 107);\"><strong>No. of CPs<br>in proper<br>condition</strong></td>\r\n
        <td style=\"height: 39.1875px; text-align: center; background-color: rgb(45, 194, 107);\"><strong>No. of CPs<br>that need<br>improvement</strong></td>\r\n
        <td style=\"height: 39.1875px; text-align: center; background-color: rgb(45, 194, 107);\"><strong>No. of CPs<br>not in use</strong></td>\r\n
        <td style=\"height: 39.1875px; text-align: center; background-color: rgb(45, 194, 107);\"><strong>No. of CPs<br>with<br>healthy<br>plantation</strong></td>\r\n
        <td style=\"height: 39.1875px; text-align: center; background-color: rgb(45, 194, 107);\"><strong>No. of CPs<br>ready for<br>plantation</strong></td>\r\n</tr>\r\n
        ${getCpStatusData(reportData.cpStatuses)}

        </tbody>\r\n</table>\r\n<p>&nbsp;</p>\r\n<table style=\"border-collapse: collapse; width: 100%;\" border=\"1\"><colgroup><col style=\"width: 99.9165%;\">
        </colgroup>\r\n<tbody>\r\n<tr>\r\n<td style=\"text-align: center;\"><strong>Compost Generated</strong></td>\r\n</tr>\r\n</tbody>\r\n</table>\r\n
        <table style=\"border-collapse: collapse; width: 100%;\" border=\"1\"><colgroup><col style=\"width: 24.9791%;\"><col style=\"width: 24.9791%;\">
        <col style=\"width: 24.9791%;\"><col style=\"width: 24.9791%;\"></colgroup>\r\n<tbody>\r\n<tr>\r\n<td style=\"text-align: center;\">
        <strong>Month</strong></td>\r\n<td style=\"text-align: center;\"><strong>Waste treated</strong></td>\r\n
        <td style=\"text-align: center;\"><strong>Compost generated</strong></td>\r\n<td style=\"text-align: center;\"><strong>Unit</strong></td>\r\n</tr>\r\n<tr>\r\n
        <td style=\"text-align: center; background-color: rgb(45, 194, 107);\">${reportData.month}</td>\r\n<td style=\"text-align: center; background-color: rgb(45, 194, 107);\">${reportData.waste}</td>\r\n
        <td style=\"text-align: center; background-color: rgb(45, 194, 107);\">${reportData.compost}</td>\r\n<td style=\"text-align: center; background-color: rgb(45, 194, 107);\">${reportData.unit}</td>\r\n</tr>\r\n</tbody>\r\n
        </table>\r\n<p>&nbsp;</p>\r\n<table style=\"border-collapse: collapse; width: 100%;\" border=\"1\"><colgroup><col style=\"width: 99.9165%;\"></colgroup>\r\n<tbody>\r\n<tr>\r\n<td>
        <strong>Corrective action suggested for CP&rsquo;s that need improvement:</strong><br><strong>Corrective action suggested for CP&rsquo;s not in use:</strong></td>\r\n</tr>\r\n</tbody>
        \r\n</table>\r\n<p>&nbsp;</p>\r\n<table style=\"border-collapse: collapse; width: 100%;\" border=\"1\"><colgroup><col style=\"width: 100%;\"></colgroup>\r\n<tbody>\r\n<tr>\r\n<td>\r\n
        <h3 style=\"text-align: center;\">Achievements</h3>\r\n<ul>\r\n<li>Point 1 </li>\r\n<li>Point 2</li>\r\n
        </ul>\r\n</td>\r\n</tr>\r\n</tbody>\r\n</table>\r\n<p>&nbsp;</p>\r\n<table style=\"border-collapse: collapse; width: 100%;\" border=\"1\"><colgroup><col style=\"width: 99.9165%;\"></colgroup>
        \r\n<tbody>\r\n<tr>\r\n<td style=\"background-color: rgb(206, 212, 217);\">\r\n<h2 style=\"text-align: center;\"><strong>SOCIAL (Policy intervention &amp; Behavioral changes&nbsp;observed)&nbsp;</strong></h2>
        \r\n</td>\r\n</tr>\r\n</tbody>\r\n</table>\r\n<table style=\"border-collapse: collapse; width: 100%;\" border=\"1\"><colgroup><col style=\"width: 99.9165%;\"></colgroup>\r\n<tbody>\r\n<tr>\r\n<td>\r\n<ul>\r\n
        <li>Point 1&nbsp;</li>\r\n<li>Point 2</li>\r\n</ul>\r\n</td>\r\n</tr>\r\n</tbody>\r\n</table>
        \r\n<p>&nbsp;</p>\r\n<table style=\"border-collapse: collapse; width: 100%;\" border=\"1\"><colgroup><col style=\"width: 100%;\"></colgroup>\r\n
        <tbody>\r\n<tr>\r\n<td style=\"text-align: center;\">\r\n<p style=\"text-align: left;\"><strong>Beneficiary Behavior Changes - </strong></p>\r\n<p style=\"text-align: left;\">
        <strong>Positive:</strong></p>\r\n<p style=\"text-align: left;\">&nbsp;</p>\r\n<p style=\"text-align: left;\"><strong>Negative:</strong></p>\r\n<p style=\"text-align: left;\">&nbsp;</p>\r\n
        <p style=\"text-align: left;\"><strong>Trainings, awareness program, competition:</strong></p>\r\n<p style=\"text-align: left;\">&nbsp;</p>\r\n<p style=\"text-align: left;\">
        <strong>Volunteer Engagement:</strong></p>\r\n<p style=\"text-align: left;\">&nbsp;</p>\r\n</td>\r\n</tr>\r\n</tbody>\r\n</table>\r\n<p>&nbsp;</p>\r\n
        <table style=\"border-collapse: collapse; width: 100%;\" border=\"1\"><colgroup><col style=\"width: 99.9165%;\"></colgroup>\r\n<tbody>\r\n
        <tr>\r\n<td style=\"background-color: rgb(206, 212, 217);\">\r\n<h3 style=\"text-align: center;\"><strong>GOVERNANCE : (Project management)&nbsp;</strong></h3>\r\n</td>\r\n
        </tr>\r\n</tbody>\r\n</table>\r\n<table style=\"border-collapse: collapse; width: 100%;\" border=\"1\"><colgroup><col style=\"width: 99.9165%;\"></colgroup>\r\n<tbody>\r\n
        <tr>\r\n<td>\r\n<p><strong>Team training:</strong></p>\r\n<p>On field trainings to FCs ongoing for:<br>1. Record keeping<br>2. Trouble shooting<br>3. Beneficiary awareness<br>4. Beneficiary behavior change</p>
        \r\n<p>***************************************************************************************** Images *************************************************************************************************</p>\r\n<p>
        <strong>Team planning:</strong></p>\r\n<p>&nbsp;</p>\r\n<p><strong>Plan for next month:</strong></p>\r\n<p>&nbsp;</p>\r\n</td>\r\n</tr>\r\n</tbody>\r\n</table>`

        return reportFormat;
    }

    const getCompanyWiseReport = (value) => {
        setSelectedCompany(value);
        setSelectedProject(null);
        setDefaultReportFormat(null);
        setReportBody(null);
        setReportData(null);
        setShowRTE(false);
        if (value) {
            getRequest(null, API_METHODS.GET, `/admin/summary-report/${value}?pageNumber=${1}`);
            !value && setShowRTE(false);
        }
    }

    const getCpStatusData = (cpStatuses) => {
        let cpStatusesHtml = "";
        let totalNoOfCP = 0, totalCpProperCondition = 0, totalCpNeedsImprovement = 0, totalCpNotInUse = 0, totalCpHealthyPlantation = 0, totalCpReadyForPlantation = 0;
        !isEmpty(cpStatuses) && cpStatuses?.forEach(cp => {
            let totalCp = cp.cpProperCondition + cp.cpNeedsImprovment + cp.notInUse;
            totalNoOfCP = totalNoOfCP + totalCp;
            totalCpProperCondition = totalCpProperCondition + cp.cpProperCondition;
            totalCpNeedsImprovement = totalCpNeedsImprovement + cp.cpNeedsImprovment;
            totalCpNotInUse = totalCpNotInUse + cp.notInUse;
            totalCpHealthyPlantation = totalCpHealthyPlantation + cp.healthyPlantation;
            totalCpReadyForPlantation = totalCpReadyForPlantation + cp.readyForPlantation;

            //CP Status
            cpStatusesHtml = cpStatusesHtml + `<tr style=\"height: 19.5938px;\">\r\n<td style=\"height: 19.5938px;\">${cp.wardName}</td>\r\n<td style=\"height: 19.5938px;\">${totalCp}</td>\r\n
            <td style=\"height: 19.5938px;\">${cp.cpProperCondition}</td>\r\n<td style=\"height: 19.5938px;\">${cp.cpNeedsImprovment}</td>\r\n<td style=\"height: 19.5938px;\">${cp.notInUse}</td>\r\n
            <td style=\"height: 19.5938px;\">${cp.healthyPlantation}</td>\r\n<td style=\"height: 19.5938px;\">${cp.readyForPlantation}</td>\r\n</tr>\r\n`
        });
        //Total
        cpStatusesHtml = cpStatusesHtml + `<tr>\r\n<td><strong>Total</strong></td>\r\n
                                        <td><strong>${totalNoOfCP}</strong></td>\r\n<td><strong>${totalCpProperCondition}</strong></td>\r\n<td><strong>${totalCpNeedsImprovement}</strong></td>\r\n<td><strong>${totalCpNotInUse}</strong></td>\r\n<td>
                                        <strong>${totalCpHealthyPlantation}</strong></td>\r\n<td><strong>${totalCpReadyForPlantation}</strong></td>\r\n</tr>\r\n`

        cpStatusesHtml = cpStatusesHtml + `<tr>\r\n<td style=\"background-color: rgb(45, 194, 107);\"><strong>Percentage</strong></td>\r\n
        <td style=\"background-color: rgb(45, 194, 107);\">&nbsp;</td>\r\n<td style=\"background-color: rgb(45, 194, 107);\"><strong>${((totalCpProperCondition / totalNoOfCP) * 100).toFixed(2)}%</strong></td>\r\n
        <td style=\"background-color: rgb(45, 194, 107);\"><strong>${((totalCpNeedsImprovement / totalNoOfCP) * 100).toFixed(2)}%</strong></td>\r\n<td style=\"background-color: rgb(45, 194, 107);\"><strong>${((totalCpNotInUse / totalNoOfCP) * 100).toFixed(2)}%</strong></td>\r\n
        <td style=\"background-color: rgb(45, 194, 107);\"><strong>${((totalCpHealthyPlantation / totalNoOfCP) * 100).toFixed(2)}%</strong></td>\r\n<td style=\"background-color: rgb(45, 194, 107);\"><strong>${((totalCpReadyForPlantation / totalNoOfCP) * 100).toFixed(2)}%</strong></td>\r\n</tr>\r\n`
        return cpStatusesHtml;
    }

    const handleAddReport = () => {
        const isVisible = showRTE ? false : true;
        setShowRTE(isVisible);
        setIsEdit(false);
    }

    const handleEditReport = () => {
        const isVisible = showRTE ? false : true;
        setIsEdit(true);
        setShowRTE(isVisible);
    }

    const addReport = () => {
        const requestBody = {
            // companyId: selectedCompany,
            projectId: selectedProject,
            monthYear: getMonthFromNumber(dayjs().subtract(1, 'month').month()) + " " + dayjs().year(),
            reportText: editorRef.current.getContent()
        }
        isEdit ?
            putRequest(requestBody, API_METHODS.PUT, `/admin/summary-report/report/${selectedReport.id}`) :
            postRequest(requestBody, API_METHODS.POST, `/admin/summary-report`);
    }

    const getCompanyProjectReportFormat = (selectedProject) => {
        const selected = projects.find(p => p.id === selectedProject);
        setSelectedProject(selected.id);
        getProjectReportFormat(null, API_METHODS.GET, `/admin/summary-report/data/${selectedProject}`);
    }

    const printReport = () => {
        const canvas = document.getElementById("summary-report");
        let ifram = document.createElement("iframe");
        ifram.style = "display:none";
        document.body.appendChild(ifram);
        let pri = ifram.contentWindow;
        pri.document.open();
        pri.document.write(canvas.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    };

    const sendToCompany = () => {
        sendReportToCompanyRequest({ reportId: selectedReport.id });
    }

    const deleteReport = () => {
        deleteReportData(null, API_METHODS.DELETE, `/admin/summary-report/${selectedReport.id}`);
    }

    useEffect(() => {
        if (deleteReportResponse.data && isEmpty(deleteReportResponse.error) && !deleteReportResponse.isLoading) {
            const { data } = deleteReportResponse;
            if (!isEmpty(data)) {
                message.info("Report deleted successfully!");
                getCompanyWiseReport(selectedCompany);
                getCompanyProjectReportFormat(selectedProject);
            }
        }
    }, [deleteReportResponse]);

    useEffect(() => {
        const { data } = responseData;
        !isEmpty(data) ? setReportData(data) : setReportData(null);
    }, [responseData]);

    useEffect(() => {
        if (postResponseData.data && isEmpty(postResponseData.error) && !postResponseData.isLoading) {
            message.success(postResponseData.data);
            getRequest(null, API_METHODS.GET, `/admin/summary-report/${selectedProject}?pageNumber=${1}`);
            setShowRTE(false);
            getCompanyWiseReport(selectedCompany);
            getCompanyProjectReportFormat(selectedProject);
        }
    }, [postResponseData]);

    useEffect(() => {
        if (putResponseData.data && isEmpty(putResponseData.error) && !putResponseData.isLoading) {
            message.success(putResponseData.data);
            getRequest(null, API_METHODS.GET, `/admin/summary-report/${selectedProject}?pageNumber=${1}`);
            setShowRTE(false);
            setIsEdit(false);
        }
    }, [putResponseData]);

    useEffect(() => {
        if (isEmpty(getProjectsByCompanyResponseData.error)) {
            setProjects(getProjectsByCompanyResponseData.data);
        }
    }, [getProjectsByCompanyResponseData]);

    useEffect(() => {
        if (getProjectReportFormatResponseData.data && isEmpty(getProjectReportFormatResponseData.error) && !getProjectReportFormatResponseData.isLoading) {
            setIsReportPresent(false);
            const { data } = getProjectReportFormatResponseData;
            const { isReportPresent } = data;
            //generate default report format with data
            if (!isReportPresent) {
                const reportDynamicData = {
                    month: getMonthFromNumber(dayjs().subtract(1, 'month').month()) + " " + dayjs().year(),
                    projectName: data?.projectDetails.projectName,
                    projectLocation: data?.projectDetails.location,
                    cpStatuses: data.cpStatus,
                    numberOfBeneficiaries: data?.projectDetails.numberOfBeneficiaries,
                    waste: data?.compostData.waste,
                    compost: data?.compostData.compost,
                    unit: data?.compostData.unit,
                }
                let reportFormat = buildCompanyReport(reportDynamicData);
                setDefaultReportFormat(reportFormat);
            } else {
                setIsReportPresent(isReportPresent);
            }
        }
    }, [getProjectReportFormatResponseData]);

    useEffect(() => {
        if (sendReportToCompanyResponse.data && isEmpty(sendReportToCompanyResponse.error) && !sendReportToCompanyResponse.isLoading) {
            message.success("Report sent to company successfully!");
        } else if (!sendReportToCompanyResponse.data && sendReportToCompanyResponse.error && !sendReportToCompanyResponse.isLoading) {
            message.error("Error sending report. Please try again!");
        }
    }, [sendReportToCompanyResponse]);

    return (
        <>
            <Row gutter={8}>
                <Col span={5}>
                    <Card title="Report Month" style={{ height: "100vh" }}>
                        <Space direction='vertical' style={{ width: "100%" }}>
                            {reportData && !isEmpty(reportData) &&
                                <>
                                    <Pagination simple={{
                                        readOnly: true,
                                    }}
                                        responsive={true}
                                        size='small'
                                        defaultCurrent={1} total={reportData[0].count} pageSize={10} showSizeChanger={false} onChange={(pageNumber) => { getRequest(null, API_METHODS.GET, `/admin/summary-report/${selectedProject}?pageNumber=${pageNumber}`); }} />
                                    {reportData.map(report => <Alert onClick={() => { setSelectedReport(report); setReportBody(report.reportText); setSelectedProject(report.projectId); }}
                                        message={report.monthYear?.toUpperCase()}
                                        // description="Success Description Success Description Success Description"
                                        type="success"
                                    />)}
                                </>}
                        </Space>
                    </Card>
                </Col>
                <Col span={19}>
                    <Card style={{ height: "100vh" }}
                        title={
                            <Form form={form}>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Form.Item name="companyId">
                                            <Select
                                                placeholder="Select Company"
                                                allowClear
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{ width: "100%" }}
                                                onChange={(value) => {
                                                    if (value) {
                                                        getProjectsByCompany(
                                                            null,
                                                            API_METHODS.GET,
                                                            `/config/project/company/${value}`
                                                        );
                                                        form.setFieldsValue({ "projectId": undefined });
                                                        setReportData(null);
                                                        setSelectedProject(null);
                                                        setReportBody(null);
                                                    }
                                                }}
                                            >
                                                {!isEmpty(company) && company.map((option) => (
                                                    <Select.Option key={option.id} value={option.id} onChange={() => { setSelectedProject(null); }}>
                                                        {option.companyName}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={14}>
                                        <Form.Item name="projectId">
                                            <Select
                                                placeholder="Select Project"
                                                allowClear
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{ width: "100%" }}
                                                onChange={(value) => { getCompanyWiseReport(value); getCompanyProjectReportFormat(value); }}
                                            >
                                                {!isEmpty(projects) && projects?.map((option) => (
                                                    <Select.Option key={option.id} value={option.id}>
                                                        {option.name + "-" + option.location}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        }
                        extra={
                            <>
                                {selectedCompany &&
                                    <Space>
                                        {selectedCompany && reportBody && !showRTE &&
                                            <Button type="default" onClick={sendToCompany} disabled={selectedReport?.status}>
                                                <b style={{ color: "black" }}>Send to Company</b>
                                            </Button>
                                        }

                                        {!showRTE ? <Space>
                                            {reportBody && <Button type="default" onClick={handleEditReport} disabled={selectedReport?.status}>
                                                Edit
                                            </Button>}
                                            {selectedProject && <Button type="default" disabled={isReportPresent} onClick={handleAddReport}>
                                                Write
                                            </Button>}
                                            {reportBody && <Button type="default" onClick={printReport}>
                                                Print
                                            </Button>}
                                            {reportBody && <Button type="default" onClick={() => deleteReport()} disabled={selectedReport?.status}>
                                                Delete
                                            </Button>}
                                        </Space>
                                            :
                                            <Space>
                                                <Button type="default" onClick={handleAddReport}>
                                                    Cancel
                                                </Button>
                                                <Button type="default" onClick={addReport}>
                                                    Save
                                                </Button>
                                            </Space>
                                        } </Space>
                                }
                            </>
                        }>
                        {!selectedCompany && <Typography.Title style={{ textAlign: "center" }}>Please select company and project!</Typography.Title>}
                        {isReportPresent && selectedProject && !reportData && <Typography.Title style={{ textAlign: "center" }}>Latest month report already present for this project!</Typography.Title>}
                        {selectedCompany && selectedProject && !reportData && !showRTE && <Typography.Title style={{ textAlign: "center" }}>There are no reports for now!</Typography.Title>}
                        {selectedCompany && showRTE && selectedProject && !isEdit && <RichTextEditor title={"Monthly Report"} visible={showRTE} close={handleAddReport} save={addReport} editorRef={editorRef} initialValue={defaultReportFormat} />}
                        {selectedCompany && showRTE && selectedProject && isEdit && <RichTextEditor title={"Monthly Report"} visible={showRTE} close={handleAddReport} save={addReport} editorRef={editorRef} initialValue={reportBody} />}
                        {selectedCompany && reportBody && !showRTE && <Card>
                            <div id="summary-report" dangerouslySetInnerHTML={{ __html: reportBody }} style={{
                                float: 'left',
                                width: "100%",
                                overflowY: 'auto',
                                height: 750,
                            }} />
                        </Card>}
                    </Card>
                </Col>
            </Row>

        </>
    );
}

export default MonthlyReports;