import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Col,
  Row,
  message,
  Radio,
  Select,
  Tabs,
} from "antd";
import { API_METHODS } from "../../constants";
import useApi from "../../hooks/useApi";
import { getClientConfig, isEmpty } from "../../generalFunctions";

function AddBlogLinks(props) {
  const [form] = Form.useForm();
  const [response, request] = useApi("/admin/blog", null, API_METHODS.POST);
  const { beneficiary } = getClientConfig();
  const { visible, close, tab } = props;
  const [activeTab, setActiveTab] = useState(tab);
  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Blog link added successfully.");
    }
  }, [response]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        request(values, API_METHODS.POST);
      })
      .catch((e) => {});
  };

  const handleCancel = () => {
    close();
  };

  const onTabChange = (key) => {
    form.resetFields();
    setActiveTab(key);
  };

  const items = [
    {
      key: "common",
      label: "Common Links",
      children: (
        <Form layout="vertical" form={form} initialValues={{ priority: false }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="link"
                label="Blog Link"
                rules={[
                  {
                    required: true,
                    message: "Blog Link is required.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="tittle"
                label="Tittle"
                rules={[
                  {
                    max: 200,
                    message: "Tittle should be maximum of 200 characters.",
                  },
                  {
                    required: true,
                    message: "Tittle is required.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    max: 500,
                    message: "Description should be maximum of 500 characters.",
                  },
                  {
                    required: true,
                    message: "Description is required.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="priority" label="Priority">
                <Radio.Group>
                  <Radio value={true}>High</Radio>
                  <Radio value={false}>Low</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ),
    },
    {
      key: "beneficiary",
      label: "For Particular Beneficiaries",
      children: (
        <Form layout="vertical" form={form} initialValues={{ priority: false }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="link"
                label="Blog Link"
                rules={[
                  {
                    required: true,
                    message: "Embebed Youtube Link is required.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="tittle"
                label="Tittle"
                rules={[
                  {
                    max: 200,
                    message: "Tittle should be maximum of 200 characters.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    max: 500,
                    message: "Description should be maximum of 500 characters.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="beneficiaryIds" label="Beneficiaries">
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {!isEmpty(beneficiary) &&
                    beneficiary.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.displayName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ),
    },
  ];
  return (
    <>
      <Modal
        title={"Add Blog"}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        okText="Save"
      >
        <Tabs
          defaultActiveKey="common"
          activeKey={activeTab}
          items={items}
          onChange={onTabChange}
        />
      </Modal>
    </>
  );
}

export default AddBlogLinks;
